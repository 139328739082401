import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import cardcover from "../images/tszshan.jpg";
import GeneralMap from '../utils/GeneralMap';
import { HiMail, HiLocationMarker, HiPhone, HiDesktopComputer, HiStar } from 'react-icons/hi';

import Seo from "../utils/seo"
import Layout from '../components/layout';
import { useActiveMenu } from "react-active-menu";

import "./temple-template.scss"

export const postQuery = graphql`
    query allTemple($id: String!) {
      temple(id: { eq: $id }) {
        activity
        activity_up
        address_c
        address_e
        area
        chatgpt_summary
        country
        createdAt
        deity
        deity_count
        deity_up
        description
        district
        duration
        email
        fax
        featured
        festival_count
        id
        image {
          url
        }
        instruction
        last_update
        lat
        level
        location
        location_up
        lon
        office_hour
        organization
        popularity
        pray_category
        record_id
        related_industry
        remarks
        services
        services_count
        supplementary
        taboo
        tel
        temple_c_name
        temple_e_name
        temple_id
        transportation
        update_record
        vegetarian_food_provided
        website
        whatsapp
      }
    }
`;



function TempleTemplate({ data }) {

  const temple = data.temple;
  const { registerSection, registerTrigger } = useActiveMenu(
    {
      offset: 120,
      smooth: true
    }
  );
  return (

    <Layout>
        <Seo title={temple.temple_c_name} description={temple.chatgpt_summary} siteUrl={`http://m2gether.com/temple/${temple.temple_id}`} />
      <Container>
        <Row>
          <Col md={2} className="p-0">
            <div className="sticky-menu">
              <button className="sticky-menu-text" ref={registerTrigger("section-1")} type="button">基本資料</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-2")} type="button">寺廟介紹</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-3")} type="button">聯絡資料</button>
              {/* <button className="sticky-menu-text" ref={registerTrigger("section-4")} type="button">交通</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-4")} type="button">相關歷史</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-4")} type="button">活動</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-5")} type="button">節誕</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-6")} type="button">祭禮指引</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-7")} type="button">禁忌</button>
              <button className="sticky-menu-text" ref={registerTrigger("section-8")} type="button">補充資料</button> */}
            </div>
          </Col>


          <Col>
            <h1 ref={registerSection("section-1")} >{temple.temple_c_name}</h1>
            <Row>
              <Col xs={8}>
                <div className="temple-detail-info">
                  {temple.popularity ? <HiStar className="temple-detail-icon" /> : ""}
                  <div>
                    {temple.popularity ? <><span className="temple-detail-withicon">{temple.popularity}.0</span>
                      <span className="temple-detail-divider">|</span></> : ""}
                    <span>{temple.country} {temple.district}</span></div>

                </div>
              </Col>
              <Col xs={4}>
                <div className="temple-detail-info">
                  <div className="temple-detail-share">
                    {/* <HiShare className="temple-detail-icon" />  <span className="temple-detail-withicon">分享</span> */}
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            {/* <div className='temple-detail-image pt-4'><img src={cardcover} alt={temple.temple_c_name} /> </div> */}

            <Row ref={registerSection("section-2")} className="py-4">
              <Col>
                {temple.deity_up ? <p>所奉神靈</p> : ""}
                {temple.deity_up && temple.deity_up.map((deity, index) => { return (<span className="temple-tag" key={index}>{deity}</span>) })}
              </Col>
              <Col>
                {temple.services ? <p>服務</p> : ""}
                {temple.services && temple.services.map((service, index) => { return (<span className="temple-tag" key={index}>{service}</span>) })}
              </Col>
              <Col>
                {temple.pray_category ? <p>分類</p> : ""}
                {temple.pray_category && temple.pray_category.map((pray_category, index) => { return (<span className="temple-tag" key={index}>{pray_category}</span>) })}
              </Col>
            </Row>
            {temple.chatgpt_summary ? <p>簡介</p> : ""}
            {temple.chatgpt_summary}

            <Row ref={registerSection("section-3")} className="py-4">
              <Row className="temple-detail-row">
                <Col md={4} className="temple-detail-card">
                  <div className="temple-detail-info"><HiLocationMarker className="temple-detail-icon" />
                    <p className="temple-detail">{temple.address_c}</p>
                    <p className="temple-detail">{temple.address_e}</p></div>
                  {temple.tel ? <div className="temple-detail-info"><HiPhone className="temple-detail-icon" /><p className="temple-detail">{temple.tel}</p></div> : ""}
                  {temple.email ? <div className="temple-detail-info"><HiMail className="temple-detail-icon" /><p className="temple-detail">{temple.email}</p></div> : ""}
                  {temple.website ? <div className="temple-detail-info"><HiDesktopComputer className="temple-detail-icon" /><p className="temple-detail">{temple.website}</p></div> : ""}
                </Col>
                <Col md={8} className="temple-detail-map">
                  <GeneralMap height={240} zoom={15} lat={temple.lat} long={temple.lon}></GeneralMap>
                </Col>
              </Row>
            </Row>

            <hr></hr>
            <p ref={registerSection("section-8")}>以上項目資料僅供參考，如發現資料有誤，歡迎<a className="temple-detail-contact" href="mailto:support@m2gether.com">電郵</a>指正以便我們了解及跟進。</p>



          </Col>
        </Row>
      </Container>
    </Layout>

  )
}


export default TempleTemplate



export const Head = ({ data }) => {
  const temple = data.temple;
  return (
    <>
      <Seo
        title={temple.temple_c_name}
        description={temple.chatgpt_summary}
        siteUrl={`https://m2gether.com/temple/${temple.temple_id}`}
      />
    </>
  );
};